import { useState, useEffect } from "react";
import "./App.css";
import papa from "papaparse";

function Table() {
  const [data, setData] = useState();

  useEffect(() => {
    fetch("data/Ghost Adventures Episodes - GA.csv")
      .then((r) => r.text())
      .then((text) => {
        setData(papa.parse(text).data);
      });
  }, []);

  const headerData = data ? data[0] : [];

  return (
    <>
      {!data && <p>Loading...</p>}
      {data && (
        <table className="table-auto">
          <thead className="bg-white border-b">
            <tr>
              {headerData.map((col, index) => (
                <th
                  key={`th-${index}`}
                  className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                >
                  {col}
                </th>
              ))}
            </tr>
          </thead>
          <thead>
            {data.slice(1).map((row, index) => (
              <tr
                key={`tr-${index}`}
                className={
                  index % 2 == 0 ? "bg-gray-100 border-b" : "bg-white border-b"
                }
              >
                {row.map((col, indexj) => (
                  <td
                    key={`tr-${index}-td-${indexj}`}
                    className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                  >
                    {col}
                  </td>
                ))}
              </tr>
            ))}
          </thead>
        </table>
      )}
    </>
  );
}

export default Table;
