import "./App.css";
import Table from "./Table";

function App() {
  return (
    <div>
      <header class="text-center mb-10">
        <h1 className="mt-3 text-[3.5rem] font-bold leading-[4rem] tracking-tight text-black">
          Ghost Adventures
        </h1>
        <p class="mt-3 text-lg leading-relaxed text-slate-400">
          Episodes &amp; More!
        </p>
      </header>
      <Table />
    </div>
  );
}

export default App;
